import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, FormDataConsumer, useDataProvider, Loading, Error, useNotify, useRefresh } from 'react-admin';
import { apiUrl } from '../global';
import { httpClient } from '../dataProvider';
import qs from 'qs';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,
  NumberField,

  SimpleForm,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,
  RadioButtonGroupInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,

  ChipField, ReferenceArrayField, SingleFieldList
} from 'react-admin';
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { UpLoadFile, PreviewImage } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Box, Divider, IconButton, Avatar } from '@material-ui/core';
import { CustomRichTextInput } from './custom/CustomRichTextInput';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useForm } from 'react-final-form';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

//import ResetOrderNum from './Doctor_ResetOrderNum';

{/* 
  import { DoctorList, DoctorCreate, DoctorEdit, DoctorShow } from './components/Doctor';

  <Resource name="CMSDoctor" list={DoctorList} create={DoctorCreate} edit={DoctorEdit} show={DoctorShow} />
  <Resource name="CMSDoctor" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 90 }
  },
  title: {
    'background-color': '#e8e8e8',
    'font-size': 12,
    'padding': 2,
    'border-radius': 3,
    'color': '#8c8c8c',
    'margin-right': 4
  },
  priceTitle: {
    'margin-left': 6
  }
});

const useTxtData = {
  page: {
    tableName: 'Doctor',
  },
  table: {
    id: 'id',
    HeadPortrait: '头像',
    NameCN: '中文名',
    NameEN: '英文名',
    Sex: '性别',
    AgePro: '年龄段',
    CommunicationLanguage: '沟通语言',
    AcceptInsurance: '支持保险',
    Education: '学历',
    Practice: '职业',
    ScopeOfTreatment: '主治范围',
    WaitingDays: '等候天数',
    Show: '显示',
    Order: '排序',
    Introduce: '介绍',
    IntroduceEN: '介绍（英文）',
    Reminders: '提醒事项',
    Notes: '备注',
    Url: '来源网址',
    UpTime: '更新时间',
    ReferralCenterID: '转诊',
    MedicalCenterID: '机构',
    CanAppointment: '开启预约',
    AppointmentStatus: '预约状态',

    Price_hz: '会诊价格',
    PriceM_hz: '会诊价格（美金）',
    gyPrice_hz: '会诊供应价格',
    gyPriceM_hz: '会诊供应价格（美金）',

    Price_cz: '初诊价格',
    PriceM_cz: '初诊价格（美金）',
    gyPrice_cz: '初诊供应价格',
    gyPriceM_cz: '初诊供应价格（美金）',

    Price_fz: '复诊价格',
    PriceM_fz: '复诊价格（美金）',
    gyPrice_fz: '复诊供应价格',
    gyPriceM_fz: '复诊供应价格（美金）',
  }
};

//分页列表页面
export const DoctorList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        {/* <ShowButton {...props} /><br /> */}
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const NameField = (props) => {
    return (
      <div>
        <TextField source="NameCN" /><br />
        <TextField className={classes.title} source="NameEN" /><br />
        <TextField className={classes.title} source="Sex" /> <TextField className={classes.title} source="AgePro" /><br />
        <span className={classes.title}>{useTxtData.table.CommunicationLanguage}</span><TextField source="CommunicationLanguage" /><br />
        <span className={classes.title}>{useTxtData.table.WaitingDays}</span><TextField source="WaitingDays" />天
      </div>
    );
  }

  const MyChip = (props) => {
    return (
      <Box style={{ margin: 1 }}><Chip size="small" label={props.record['SortName']} /></Box>
    );
  }

  const EducationField = (props) => {
    return (
      <div>
        <span className={classes.title}>{useTxtData.table.Education}</span><TextField source="Education" /><br />
        <span className={classes.title}>{useTxtData.table.Practice}</span><TextField source="Practice" /><br />
        <span className={classes.title}>{useTxtData.table.ScopeOfTreatment}</span><TextField source="ScopeOfTreatment" /><br />
        <Box display="flex" alignItems='center' mt={1} mb={1}>
          <Box><span className={classes.title}>所属分类</span></Box>
          <Box flexWrap='wrap' style={{ maxWidth: 365 }}>
            <ReferenceArrayField reference="Admin/CMSDoctorSort" source="doctorSortIDs">
              <SingleFieldList>
                <MyChip />
              </SingleFieldList>
            </ReferenceArrayField>
          </Box>
        </Box>
      </div>
    );
  }

  const OrderShow = (props) => {
    return (
      <div style={{ minWidth: 150 }}>
        <span className={classes.title}>{useTxtData.table.UpTime}</span><br /><TextField source="UpTime" className={classes.title} /><br />

        <Box display="flex">
          <Box m={1}>
            <span className={classes.title}>{useTxtData.table.Order}</span><br /><TextField source="Order" defaultValue={0} />
          </Box>
          <Box m={1}>
            <span className={classes.title}>{useTxtData.table.Show}</span><BooleanField source="Show" />
          </Box>
          <Box m={1}>
            <span className={classes.title}>{useTxtData.table.CanAppointment}</span><BooleanField source="CanAppointment" />
          </Box>
        </Box>
      </div>
    );
  }


  const ReferralAndMedical = (props) => {
    return (
      <div>
        {/* <TextField source="ReferralCenterID" /><br /> */}
        <span className={classes.title}>{useTxtData.table.ReferralCenterID}</span><ReferenceField source="ReferralCenterID" reference="Admin/CMSReferralCenter" link=''>
          <TextField source="Name" />
        </ReferenceField><br />
        {/* <TextField source="MedicalCenterID" /> */}
        <span className={classes.title}>{useTxtData.table.MedicalCenterID}</span><ReferenceField source="MedicalCenterID" reference="Admin/CMSMedicalCenter" link=''>
          <TextField source="Name" />
        </ReferenceField>
      </div>
    );
  }

  const PriceShow = (props) => {
    let style = { marginLeft: 10 };
    let { name, p1, p2, p3, p4 } = props;
    return (<div>
      <span className={classes.title}>{name}</span><span className={`${classes.title} ${classes.priceTitle}`} >售价</span><span>￥{props.record[p1]}(${props.record[p2]})</span><span className={`${classes.title} ${classes.priceTitle}`}>成本</span><span>￥{props.record[p3]}(${props.record[p4]})</span>
    </div>);
  }

  const PriceShowAll = (props) => {
    return (
      <div style={{ minWidth: 310, padding: '10px 0' }}>
        <PriceShow {...props} name={'会诊'} p1='Price_hz' p2='PriceM_hz' p3='gyPrice_hz' p4='gyPriceM_hz' />
        <Divider style={{ margin: '5px 0', }} />
        <PriceShow {...props} name={'初诊'} p1='Price_cz' p2='PriceM_cz' p3='gyPrice_cz' p4='gyPriceM_cz' />
        <Divider style={{ margin: '5px 0', }} />
        <PriceShow {...props} name={'复诊'} p1='Price_fz' p2='PriceM_fz' p3='gyPrice_fz' p4='gyPriceM_fz' />
      </div>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />

        {/* <TextField label={useTxtData.table.ReferralCenterID} source="ReferralCenterID" />
        <TextField label={useTxtData.table.MedicalCenterID} source="MedicalCenterID" /> */}
        <ReferralAndMedical label='转诊/机构' />

        <PreviewImage label={useTxtData.table.HeadPortrait} source="HeadPortrait" className={classes.imageField} />

        {/* <TextField label={useTxtData.table.NameCN} source="NameCN" />
        <TextField label={useTxtData.table.NameEN} source="NameEN" />
        <TextField label={useTxtData.table.Sex} source="Sex" />
        <TextField label={useTxtData.table.AgePro} source="AgePro" /> 
        <TextField label={useTxtData.table.WaitingDays} source="WaitingDays" />
        */}
        <NameField label={'医生信息'} />

        {/* <TextField label={useTxtData.table.CommunicationLanguage} source="CommunicationLanguage" />
        <TextField label={useTxtData.table.Education} source="Education" />
        <TextField label={useTxtData.table.Practice} source="Practice" /> */}
        <EducationField label={'主治/学历/职业/分类'} />



        {/* <TextField label={useTxtData.table.AcceptInsurance} source="AcceptInsurance" /> */}


        {/* <TextField label={useTxtData.table.Order} source="Order" />
        <BooleanField label={useTxtData.table.Show} source="Show" /> */}


        {/* <TextField label={useTxtData.table.Introduce} source="Introduce" />
        <TextField label={useTxtData.table.IntroduceEN} source="IntroduceEN" /> */}
        {/* <TextField label={useTxtData.table.Reminders} source="Reminders" />
        <TextField label={useTxtData.table.Notes} source="Notes" /> */}
        {/* <TextField label={useTxtData.table.Url} source="Url" /> */}



        {/* <TextField label={useTxtData.table.Price_hz} source="Price_hz" />
        <TextField label={useTxtData.table.PriceM_hz} source="PriceM_hz" />
        <TextField label={useTxtData.table.gyPrice_hz} source="gyPrice_hz" />
        <TextField label={useTxtData.table.gyPriceM_hz} source="gyPriceM_hz" /> */}

        {/* <TextField label={useTxtData.table.Price_cz} source="Price_cz" />
        <TextField label={useTxtData.table.PriceM_cz} source="PriceM_cz" />
        <TextField label={useTxtData.table.gyPrice_cz} source="gyPrice_cz" />
        <TextField label={useTxtData.table.gyPriceM_cz} source="gyPriceM_cz" /> */}

        {/* <TextField label={useTxtData.table.Price_fz} source="Price_fz" />
        <TextField label={useTxtData.table.PriceM_fz} source="PriceM_fz" />
        <TextField label={useTxtData.table.gyPrice_fz} source="gyPrice_fz" />
        <TextField label={useTxtData.table.gyPriceM_fz} source="gyPriceM_fz" /> */}
        <PriceShowAll label='价格' />

        <OrderShow label='状态' />

        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const DoctorShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.HeadPortrait} source="HeadPortrait" />
        <TextField label={useTxtData.table.NameCN} source="NameCN" />
        <TextField label={useTxtData.table.NameEN} source="NameEN" />
        <TextField label={useTxtData.table.Sex} source="Sex" />
        <TextField label={useTxtData.table.AgePro} source="AgePro" />
        <TextField label={useTxtData.table.CommunicationLanguage} source="CommunicationLanguage" />
        <TextField label={useTxtData.table.AcceptInsurance} source="AcceptInsurance" />
        <TextField label={useTxtData.table.Education} source="Education" />
        <TextField label={useTxtData.table.Practice} source="Practice" />
        <TextField label={useTxtData.table.ScopeOfTreatment} source="ScopeOfTreatment" />
        <TextField label={useTxtData.table.WaitingDays} source="WaitingDays" />
        <TextField label={useTxtData.table.Show} source="Show" />
        <TextField label={useTxtData.table.Order} source="Order" />
        <TextField label={useTxtData.table.Introduce} source="Introduce" />
        <TextField label={useTxtData.table.IntroduceEN} source="IntroduceEN" />
        <TextField label={useTxtData.table.Reminders} source="Reminders" />
        <TextField label={useTxtData.table.Notes} source="Notes" />
        <TextField label={useTxtData.table.Url} source="Url" />
        <TextField label={useTxtData.table.UpTime} source="UpTime" />
        <TextField label={useTxtData.table.ReferralCenterID} source="ReferralCenterID" />
        <TextField label={useTxtData.table.MedicalCenterID} source="MedicalCenterID" />
        <TextField label={useTxtData.table.CanAppointment} source="CanAppointment" />
        <TextField label={useTxtData.table.Price_hz} source="Price_hz" />
        <TextField label={useTxtData.table.PriceM_hz} source="PriceM_hz" />
        <TextField label={useTxtData.table.gyPrice_hz} source="gyPrice_hz" />
        <TextField label={useTxtData.table.gyPriceM_hz} source="gyPriceM_hz" />
        <TextField label={useTxtData.table.Price_cz} source="Price_cz" />
        <TextField label={useTxtData.table.PriceM_cz} source="PriceM_cz" />
        <TextField label={useTxtData.table.gyPrice_cz} source="gyPrice_cz" />
        <TextField label={useTxtData.table.gyPriceM_cz} source="gyPriceM_cz" />
        <TextField label={useTxtData.table.Price_fz} source="Price_fz" />
        <TextField label={useTxtData.table.PriceM_fz} source="PriceM_fz" />
        <TextField label={useTxtData.table.gyPrice_fz} source="gyPrice_fz" />
        <TextField label={useTxtData.table.gyPriceM_fz} source="gyPriceM_fz" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const DoctorEdit = (props) => {
  const PostEditActions = (props) => {
    console.log('props', props);
    const { basePath } = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const id = props.data.id;

    const deleteHandler = () => {
      if (window.confirm('确认要删除吗?')) {
        httpClient(`${apiUrl}/Admin/CMSDoctor/Delete/${id}`, {
          method: 'DELETE',
        }).then(({ json }) => {
          console.log('json', json);
          if (json.Code === 1) {
            redirect(basePath);
          }
          else {
            notify(json.Message, 'error');
            refresh();
          }
        })

      }
    }

    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <Button style={{ color: 'red' }} label="删除" onClick={() => deleteHandler()} variant="text">
          <DeleteOutlineIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const DoctorCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单

const UseFormComponent = ({ children }) => {
  //两个ReferenceInput联动的问题，默认次级选中的值不会被清空
  //https://github.com/marmelab/react-admin/issues/2431 官方的问题
  //https://github.com/marmelab/react-admin/issues/3676#issuecomment-595240330 解决问题的方法
  const form = useForm();
  if (typeof children === 'function')
    return children && children(form);
};

const MyForm = (props) => {
  let { Edit, Create } = props;

  const ChangePrice = (props) => {

    let { p1, p2 } = props;
    const [v, setV] = useState(7);

    const vChange = (event) => {
      let value = parseFloat(event.target.value);
      if (isNaN(value)) value = 7;
      setV(value);
    }

    return (
      <FormDataConsumer>
        {(props) => {
          var { formData } = props;
          return <UseFormComponent>{
            (form) => {
              const leftClick = () => {
                form.change(p1, formData[p2] * v);
              }
              const rightClick = () => {
                form.change(p2, parseFloat((formData[p1] / v).toFixed(2)));
              }
              return (<Box display="flex" justifyContent='center' alignItems='center' style={{ marginTop: -32 }} >
                <Box>
                  <IconButton size="small" onClick={leftClick}><ChevronLeftIcon /></IconButton>
                </Box>
                <Box>
                  <input onChange={vChange} type='text' style={{ width: 20, textAlign: 'center', verticalAlign: 'bottom' }} defaultValue={v} />
                </Box>
                <Box>
                  <IconButton size="small" onClick={rightClick}><ChevronRightIcon /></IconButton>
                </Box>
              </Box>)
            }
          }</UseFormComponent>
        }}

      </FormDataConsumer>
    );
  }

  return (
    <TabbedForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
      <FormTab label="基础数据">
        <Box display="flex" fullWidth>
          <Box flex={5} m={1}>
            <Box display="flex" fullWidth>
              <Box flex={1} m={1}>
                <BooleanInput label={useTxtData.table.Show} source="Show" validate={[]} />
              </Box>
              <Box flex={1} m={1}>
                <ReferenceInput label={useTxtData.table.ReferralCenterID} source="ReferralCenterID" reference="Admin/CMSReferralCenter" validate={required()} fullWidth perPage={99999}>
                  <SelectInput optionText="Name" />
                </ReferenceInput>
              </Box>
              <Box flex={1} m={1}>
                <ReferenceInput label={useTxtData.table.MedicalCenterID} source="MedicalCenterID" reference="Admin/CMSMedicalCenter" validate={required()} fullWidth perPage={99999}>
                  <SelectInput optionText="Name" />
                </ReferenceInput>
              </Box>
              <Box flex={1} m={1}>
                <BooleanInput label={useTxtData.table.CanAppointment} source="CanAppointment" validate={[]} />
              </Box>
            </Box>
            <Box display="flex" fullWidth>
              <Box flex={1} m={1}><TextInput label={useTxtData.table.NameCN} source="NameCN" validate={[]} fullWidth /></Box>
              <Box flex={1} m={1}><TextInput label={useTxtData.table.NameEN} source="NameEN" validate={[required()]} fullWidth /></Box>
              <Box flex={1} m={1}>
                <SelectInput label={useTxtData.table.AgePro} source="AgePro" validate={[]} choices={[
                  { id: '中年', name: '中年' },
                  { id: '老年', name: '老年' },
                  { id: '青年', name: '青年' },
                ]} fullWidth />
              </Box>
              <Box flex={1} m={1}>
                {/* <BooleanInput label={useTxtData.table.CanAppointment} source="CanAppointment" validate={[]} /> */}
                <SelectInput label={useTxtData.table.AppointmentStatus} source="AppointmentStatus" validate={[required()]} choices={[
                  { id: '立即预约', name: '立即预约' },
                  { id: '待定预约', name: '待定预约' },
                ]} fullWidth />
              </Box>
            </Box>
            <Box display="flex" fullWidth>
              <Box flex={1} m={1}>
                <TextInput label={useTxtData.table.CommunicationLanguage} source="CommunicationLanguage" validate={[]} fullWidth />
              </Box>
              <Box flex={1} m={1}>
                <TextInput label={useTxtData.table.Practice} source="Practice" validate={[]} fullWidth />
              </Box>
              <Box flex={1} m={1}>
                <NumberInput label={useTxtData.table.WaitingDays} source="WaitingDays" validate={[required()]} fullWidth />
              </Box>
              <Box flex={1} m={1}>
                <BooleanInput label={useTxtData.table.AcceptInsurance} source="AcceptInsurance" validate={[]} />
              </Box>
            </Box>

            <Box display="flex" fullWidth>
              <Box flex={1} m={1}>
                <TextInput label={useTxtData.table.Education} source="Education" validate={[]} fullWidth />
              </Box>
            </Box>

            <Box display="flex" fullWidth>
              <Box flex={1} m={1}>
                <TextInput label={useTxtData.table.ScopeOfTreatment} source="ScopeOfTreatment" validate={[required()]} fullWidth />
              </Box>
            </Box>

          </Box>

          <Box flex={2} m={1}>
            <Box display="flex" >
              <Box m={1}>
                <UpLoadFile label={useTxtData.table.HeadPortrait} source="HeadPortrait" validate={[required()]} />
              </Box>
            </Box>
            <Box display="flex" >
              <Box m={1}>
                <RadioButtonGroupInput label={useTxtData.table.Sex} source="Sex" validate={[]} choices={[
                  { id: '男', name: '男' },
                  { id: '女', name: '女' },
                ]} />
              </Box>
            </Box>
            <Box display="flex">
              <Box m={1}>
                <NumberInput label={useTxtData.table.Order} source="Order" validate={[required()]} defaultValue={0} />
              </Box>
            </Box>
          </Box>
        </Box>
      </FormTab>

      <FormTab label='分类'>
        <MyReferenceArrayInput />
        {/* <ReferenceArrayInput label='所属分类' source="doctorSortIDs" reference="Admin/CMSDoctorSort" fullWidth validate={[required]}>
          <SelectArrayInput optionText="SortName" />
        </ReferenceArrayInput> */}
      </FormTab>

      <FormTab label='价格'>

        <Box display="flex" fullWidth>
          <Box display="flex" flexDirection="column">
            <Box display="flex" >
              <Box m={1}>
                <NumberInput label={useTxtData.table.Price_hz} source="Price_hz" validate={[]} />
              </Box>
              <Box m={1}>
                <NumberInput label={useTxtData.table.PriceM_hz} source="PriceM_hz" validate={[]} />
              </Box>
            </Box>
            <ChangePrice p1={'Price_hz'} p2={'PriceM_hz'} />
          </Box>
          <Box display="flex" flexDirection="column" ml={4}>
            <Box display="flex" >
              <Box m={1}>
                <NumberInput label={useTxtData.table.gyPrice_hz} source="gyPrice_hz" validate={[]} />
              </Box>
              <Box m={1}>
                <NumberInput label={useTxtData.table.gyPriceM_hz} source="gyPriceM_hz" validate={[]} />
              </Box>
            </Box>
            <ChangePrice p1={'gyPrice_hz'} p2={'gyPriceM_hz'} />
          </Box>
        </Box>

        <Box display="flex" fullWidth>
          <Box display="flex" flexDirection="column">
            <Box display="flex" >
              <Box m={1}>
                <NumberInput label={useTxtData.table.Price_cz} source="Price_cz" validate={[]} />
              </Box>
              <Box m={1}>
                <NumberInput label={useTxtData.table.PriceM_cz} source="PriceM_cz" validate={[]} />
              </Box>
            </Box>
            <ChangePrice p1={'Price_cz'} p2={'PriceM_cz'} />
          </Box>
          <Box display="flex" flexDirection="column" ml={4}>
            <Box display="flex" >
              <Box m={1}>
                <NumberInput label={useTxtData.table.gyPrice_cz} source="gyPrice_cz" validate={[]} />
              </Box>
              <Box m={1}>
                <NumberInput label={useTxtData.table.gyPriceM_cz} source="gyPriceM_cz" validate={[]} />
              </Box>
            </Box>
            <ChangePrice p1={'gyPrice_cz'} p2={'gyPriceM_cz'} />
          </Box>
        </Box>

        <Box display="flex" fullWidth>
          <Box display="flex" flexDirection="column">
            <Box display="flex" >
              <Box m={1}>
                <NumberInput label={useTxtData.table.Price_fz} source="Price_fz" validate={[]} />
              </Box>
              <Box m={1}>
                <NumberInput label={useTxtData.table.PriceM_fz} source="PriceM_fz" validate={[]} />
              </Box>
            </Box>
            <ChangePrice p1={'Price_fz'} p2={'PriceM_fz'} />
          </Box>
          <Box display="flex" flexDirection="column" ml={4}>
            <Box display="flex" >
              <Box m={1}>
                <NumberInput label={useTxtData.table.gyPrice_fz} source="gyPrice_fz" validate={[]} />
              </Box>
              <Box m={1}>
                <NumberInput label={useTxtData.table.gyPriceM_fz} source="gyPriceM_fz" validate={[]} />
              </Box>
            </Box>
            <ChangePrice p1={'gyPrice_fz'} p2={'gyPriceM_fz'} />
          </Box>
        </Box>

      </FormTab>

      <FormTab label='介绍'>
        <TextInput label={useTxtData.table.Url} source="Url" validate={[]} fullWidth />
        <CustomRichTextInput label={useTxtData.table.Introduce} source="Introduce" validate={[]} />
        <CustomRichTextInput label={useTxtData.table.IntroduceEN} source="IntroduceEN" validate={[]} />
        <CustomRichTextInput label={useTxtData.table.Reminders} source="Reminders" validate={[]} />
      </FormTab>

    </TabbedForm>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
Array.prototype.remove = function (val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

const MyReferenceArrayInput = (props) => {
  const { doctorSortIDs } = props.record;
  console.log('doctorSortIDs', doctorSortIDs)

  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    httpClient(`${apiUrl}/Admin/CMSDoctorSort/GetList?_order=ASC&_page=0&_pagesize=9999&_sort=id`, {
      method: 'GET',
      //contentType: 'application/x-www-form-urlencoded',
      //body: qs.stringify({ EnumName: props.EnumName })
    }).then(({ json }) => {
      setLoading(false);
      if (json.Code == 1)
        setData(json.Result);
      else
        setError(json.Message);
    })
  }, []);

  const handleClose = () => {
    setError(null);
  }

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Snackbar open={error ? true : false} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error">
          {error}
        </Alert>
      </Snackbar>
    )
  if (!data) return null;


  return (
    <FormDataConsumer>
      {(props) => {
        var { formData } = props;
        return <UseFormComponent>{
          (form) => {
            const chipClick = (itemId) => {
              let oldIds = formData.doctorSortIDs === undefined ? [] : formData.doctorSortIDs;
              if (oldIds.indexOf(itemId) < 0) { oldIds.push(itemId); }
              else { oldIds.remove(itemId); }
              form.change('doctorSortIDs', []);
              form.change('doctorSortIDs', oldIds);
            }

            console.log('show');
            return data.data.map((item, index) => {
              //return (<span>{item.SortName}</span>);
              let Ji = item.Ji;
              if (Ji === 0) {
                let subItems = data.data.filter(t => t.ParentProductSortID === item.id);
                return (
                  <Box>
                    <Box m={1} display='flex'>
                      <Box>{item.SortName}</Box>
                    </Box>
                    <Box m={2} display='flex' flexWrap="wrap">
                      {
                        subItems.map((item2, index2) => {
                          console.log('doctorSortIDsdoctorSortIDs', formData.doctorSortIDs)
                          let isDone = formData.doctorSortIDs !== undefined && formData.doctorSortIDs.indexOf(item2.id) > -1;
                          return (
                            <Box m={1}><Chip color={isDone ? "secondary" : ''} onClick={() => chipClick(item2.id)} avatar={isDone && <Avatar><DoneIcon /></Avatar>} label={item2.id + '-' + item2.SortName} />
                            </Box>
                          );
                        })
                      }
                    </Box>
                  </Box>
                );
              }
              else
                return null;
            })
          }
        }</UseFormComponent>
      }}
    </FormDataConsumer>)
}
