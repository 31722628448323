import * as React from 'react';
import { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, useListContext } from 'react-admin';
import { Form } from 'react-final-form';
import { httpClient } from '../dataProvider';
import { apiUrl } from '../global';
import {
  useRedirect,
  useNotify,
  useRefresh,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { UpLoadFile } from './custom/UpLoadFile';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowBack, Search } from '@material-ui/icons';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Box, Avatar, Typography, Button as CButton } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';

//import ResetOrderNum from './Member_ResetOrderNum';

{/* 
  import { MemberList, MemberCreate, MemberEdit, MemberShow } from './components/Member';

  <Resource name="CMSMember" list={MemberList} create={MemberCreate} edit={MemberEdit} show={MemberShow} />
  <Resource name="CMSMember" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: 'Member',
  },
  table: {
    id: 'id',
    Mobile: '手机',
    AvatarUrl: '头像',
    Email: '邮箱',
    NickName: '昵称',
    Password: '密码',
    IsLock: '锁定',
    CreateTime: '创建时间',
    UpdateTime: '更新时间',
  }
};

//分页列表页面
export const MemberList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, } = useListContext();
    if (props.context === "button") return null;
    const onSubmit = values => { if (Object.keys(values).length > 0) { setFilters(values); } else { setFilters({}, []); } };
    const resetFilter = () => { setFilters({}, []); };
    return (
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display='flex' alignItems='center'>
              <Box component="span" mr={1}>
                <TextInput label="昵称" source="name" alwaysOn resettable />
              </Box>
              <Box component="span" mr={1}>
                <TextInput label="手机" source="mobile" alwaysOn resettable />
              </Box>
              <Box component="span" mr={1}>
                <TextInput label="邮箱" source="email" alwaysOn resettable />
              </Box>
              <Box component="span" mr={1} mb={2.5}>
                <CButton variant="contained" color="secondary" type="submit" startIcon={<Search />}>搜索</CButton>
              </Box>
              <Box component="span" mr={1} mb={2.5}>
                <CButton variant="outlined" onClick={resetFilter}>重置</CButton>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        <ShowButton {...props} /><br />
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const UserMsg = (props) => {
    let { record } = props;
    return (
      <Box display='flex' alignItems='center' >
        <Box display='flex' flexDirection='column' alignItems='center' >
          <Box>
            <Avatar alt={record.NickName} src={record.AvatarUrl} />
          </Box>
          <Box>
            {record.NickName}
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' ml={1}>
          <Box display='flex' alignItems='center'>
            <PhoneAndroidIcon style={{ fontSize: 16 }} /> {record.Mobile}
          </Box>
          <Box display='flex' alignItems='center'>
            <MailOutlineIcon style={{ fontSize: 16 }} /> {record.Email}
          </Box>
        </Box>
      </Box>
    );
  }

  const ShowUserMsg = (props) => {
    let { record } = props;
    return (
      <React.Fragment>
        {
          record['Mobile'].length > 0 ? <TextField source="Mobile" /> : <TextField source="Email" />
        }
        (<TextField source="NickName" />)
      </React.Fragment>
    );
  }

  const UserMsg2 = (props) => {
    return (
      <Box display='flex' flexDirection='column'>
        <Box>
          <ReferenceField source="PMemberID" reference="Admin/CMSMember" link={false} >
            <ShowUserMsg />
          </ReferenceField>
        </Box>
        <Box>
          <ReferenceField source="TMemberID" reference="Admin/CMSMember" link={false}>
            <ShowUserMsg />
          </ReferenceField>
        </Box>
      </Box>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />

        {/* <TextField label={useTxtData.table.Mobile} source="Mobile" />
        <TextField label={useTxtData.table.AvatarUrl} source="AvatarUrl" />
        <TextField label={useTxtData.table.NickName} source="NickName" />
        <TextField label={useTxtData.table.Email} source="Email" /> */}
        <UserMsg label='会员信息' />

        <UserMsg2 label='父/顶级服务商' />

        {/* <TextField label={useTxtData.table.Password} source="Password" /> */}
        <BooleanField label={useTxtData.table.IsLock} source="IsLock" />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const MemberShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  const ShowUserMsg = (props) => {
    let { record, title } = props;
    return (
      <React.Fragment>
        <Typography variant='body2' display="inline" gutterBottom>{title}: </Typography>
        {
          record['Mobile'].length > 0 ? <TextField source="Mobile" /> : <TextField source="Email" />
        }
        (<TextField source="NickName" />)
      </React.Fragment>
    );
  }

  const UserMsg2 = (props) => {
    return (
      <>
        <Box color='text.secondary'><Typography variant="caption" display="block" gutterBottom>{props.label}</Typography></Box>
        <Box display='flex' flexDirection='row'>
          <Box mr={2}>
            <ReferenceField source="PMemberID" reference="Admin/CMSMember" link={false} >
              <ShowUserMsg title={'父级'} />
            </ReferenceField>
          </Box>
          <Box mr={2}>
            <ReferenceField source="TMemberID" reference="Admin/CMSMember" link={false}>
              <ShowUserMsg title={'顶级'} />
            </ReferenceField>
          </Box>
        </Box>
      </>
    );
  }

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.Mobile} source="Mobile" />
        <TextField label={useTxtData.table.AvatarUrl} source="AvatarUrl" />
        <TextField label={useTxtData.table.Email} source="Email" />
        <TextField label={useTxtData.table.NickName} source="NickName" />
        {/* <TextField label={useTxtData.table.Password} source="Password" /> */}

        <UserMsg2 label='父/顶级服务商' />

        <TextField label={useTxtData.table.IsLock} source="IsLock" />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const MemberEdit = (props) => {
  const { basePath, match } = props; const redirect = useRedirect();
  const { id } = match.params;

  const PostEditActions = (props) => {
    return (
      <TopToolbar {...props}>
        <Button label={'升级成为顶级服务商'} onClick={() => redirect(`${match.url}/shengji`)} />
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <Box display={'flex'} alignItems={'center'} style={{ width: '100%' }}>
          <Box><SaveButton /></Box>
          <Box flex={1}></Box>
          <Box><DeleteUser id={id} ml={2} /></Box>
        </Box>
      </Toolbar>
    );
  };

  //返回到编辑页面
  const handleClose = () => {
    redirect(match.url);//编辑页面
  };

  return (
    <>
      <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
        <MyForm Edit={true} toolbar={<PostEditToolbar />} />
      </Edit>
      <Route path={`${match.url}/shengji`} >
        {({ match }) => (
          <Drawer
            open={!!match}
            anchor="right"
            onClose={handleClose}
          >
            <ShengJi {...props}
              onCancel={handleClose}
            />
          </Drawer>
        )}
      </Route>
    </>
  );
};

const DeleteUser = (props) => {
  const { id } = props;
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const deleteUser = () => {
    if (loading) return;
    if (window.confirm('确定要执行该删除操作吗？')) {
      setLoading(true);
      httpClient(`${apiUrl}/Admin/CMSMember/Delete/${id}`, {
        method: 'DELETE'
      }).then(({ json }) => {
        if (json.Code === 1) {
          notify('Success', 'success');
          redirect(`/Admin/CMSMember`)
        }
        else
          notify(json.Message, 'info');
      }).catch((err) => {
        notify(`异常: ${err}`, 'error');
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  return (
    <Button onClick={deleteUser} style={{ color: 'red' }} >
      <>
        {loading && <CircularProgress size={15} />}<span>删除会员</span>
      </>
    </Button>
  );
}

const ShengJi = (props) => {
  const redirect = useRedirect();
  const { basePath, onCancel, match } = props;
  const { id } = match.params;
  const PostEditActions = (props) => {
    return (
      <TopToolbar {...props}>
        <Button label="返回" onClick={() => onCancel()} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const onSuccess = () => {
    redirect(`/Admin/CMSMember2?filter={"key":"${id}"}`)
  };
  return (
    <Create onSuccess={onSuccess} undoable={false} {...props} actions={<PostEditActions />} resource={`${match.url.substring(1)}/shengji`} >
      <SimpleForm {...props} >
        <TextInput label={'真实姓名'} source="RealName" validate={[required()]} />
        <UpLoadFile label={'证件'} source="CertificateAttachment" validate={[]} multiple />
        <NumberInput label={'平台提成比例'} source="PlatformCommission" validate={[required()]} defaultValue={0.12} />
        <RichTextInput label={'备注'} source="Notes" validate={[]} toolbar={[['bold', 'italic', 'underline', 'link']]} />

        <BooleanInput fullWidth label={'强制升级成为顶级服务商，会取消已关联的服务商信息'} source="AlwayDo" validate={[]} defaultValue={false} />
      </SimpleForm>
    </Create>
  );
};



////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
/* export const MemberCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
}; */

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
      <TextInput label={useTxtData.table.Mobile} source="Mobile" validate={[]} />
      {/* <TextInput label={useTxtData.table.AvatarUrl} source="AvatarUrl" validate={[required()]} /> */}
      <TextInput label={useTxtData.table.Email} source="Email" validate={[]} />
      <TextInput label={useTxtData.table.NickName} source="NickName" validate={[]} />
      {/* <TextInput label={useTxtData.table.Password} source="Password" validate={[required()]} /> */}
      <BooleanInput label={useTxtData.table.IsLock} source="IsLock" validate={[required()]} />
      {/* <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} />
      <TextInput label={useTxtData.table.UpdateTime} source="UpdateTime" validate={[required()]} /> */}
    </SimpleForm>
  );
}