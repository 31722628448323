import * as React from 'react';
import { Fragment, useCallback } from 'react';
import {
    useRedirect,
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    NumberInput,
    BulkDeleteWithConfirmButton,
    Button,
    SaveButton,
    Toolbar,
    TopToolbar,
    DeleteWithConfirmButton,
    FileInput, ImageField, ImageInput, FileField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

export const UpLoadFile = (props) => {

    const handleImageChange = fileObj => {
        //console.log('handleImageChange', fileObj)
        //fileObj[0].
        //setPreviewPath(fileObj[0].preview);
    };
    if (props.notImg)
        return (
            <FileInput {...props} options={{ onDrop: handleImageChange }} >
                <PreviewFile source={props.source} />
            </FileInput>
        );
    else
        return (
            <ImageInput {...props} options={{ onDrop: handleImageChange }} >
                {/* <TextField source="Logo" title="LOGO" /> */}
                <PreviewImage source={props.source} />
            </ImageInput>
        );
};

export const PreviewImage = (props) => {
    let { record, source, label } = props;
    if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }

    return <ImageField {...props} label={label} record={record} source={source} />
}
export const PreviewFile = ({ record, source }) => {
    if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }
    else {
        record = {
            [source]: record.rawFile.name
        }
    }
    return <FileField record={record} source={source} title={record[source]} />
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const FirstImageField = (props) => {
    //console.log('props', props)
    let { label, record, source, subSource } = props;
    let imgs = record[source];
    //console.log('imgs', imgs);
    if (imgs.length > 0)
        return <div label={label} className={props.className}><img src={subSource != undefined ? imgs[0][subSource] : imgs[0]} /></div>;
    return null;
};