import React, { useState } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { TextField, Button } from '@material-ui/core';

import { apiUrl } from '../global';
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { httpClient } from '../dataProvider';

const ChangePwd = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const [oldPwd, setOldPwd] = useState('');
    let [newPwd, setNewPwd] = useState('');
    let [newPwd2, setNew2Pwd] = useState('');

    const oldPwdHandle = (e) => {
        setOldPwd(e.target.value);
    }
    const newPwdHandle = (e) => {
        setNewPwd(e.target.value);
    }
    const newPwd2Handle = (e) => {
        setNew2Pwd(e.target.value);
    }

    const formSmt = () => {
        httpClient(apiUrl + '/Admin/CMSAdmin/ChangeLoginAdminPwd', {
            method: 'POST',
            body: JSON.stringify({ oldPwd, newPwd, newPwd2 }),
        }).then(({ headers, json }) => {
            notify(json.Message);
            if (json.Code == 1) {
                redirect('/');
            }
        });
    }

    return (
        <Card>
            <Title title="修改密码" />
            <CardContent>
                <form autoComplete="off">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 240, paddingLeft: 25, height: 200, justifyContent: 'space-between' }}>
                        <TextField required label="旧密码" type="password" value={oldPwd} onChange={oldPwdHandle} />
                        <TextField required label="新密码" type="password" value={newPwd} onChange={newPwdHandle} />
                        <TextField required label="新密码" type="password" value={newPwd2} onChange={newPwd2Handle} />
                    </div>
                    <div style={{ paddingLeft: 25, paddingTop: 25 }}>
                        <Button variant="contained" color="primary" onClick={formSmt}>修 改</Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    );
};

export default ChangePwd;