import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, FormDataConsumer } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { CustomRichTextInput } from './custom/CustomRichTextInput';
import { UpLoadFile, PreviewImage } from './custom/UpLoadFile';
import { SelectByEnum, TextByEnum } from './custom/Enum';
import { ArrowBack } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { useForm } from 'react-final-form';
//import ResetOrderNum from './MedicalCenter_ResetOrderNum';

{/* 
  import { MedicalCenterList, MedicalCenterCreate, MedicalCenterEdit, MedicalCenterShow } from './components/MedicalCenter';

  <Resource name="CMSMedicalCenter" list={MedicalCenterList} create={MedicalCenterCreate} edit={MedicalCenterEdit} show={MedicalCenterShow} />
  <Resource name="CMSMedicalCenter" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: 'MedicalCenter',
  },
  table: {
    id: 'id',
    Name: '机构名称',
    NameEN: '机构名称(英文)',
    Logo: 'Logo',
    CountryID: '国家',
    CityID: '城市',
    TimeZoneID: '时区',
    Contacts: '联系人',
    ContactsMobiles: '手机号',
    ContactsEmails: '邮箱',
    Address: '地址',
    Introduce: '介绍',
    IntroduceEN: '介绍(英文)',
    Notes: '备注',
    OrderNum: '排序',

    HospitalCategory: '医院类别',
    MedicalScope: '医疗范围',
    CommunicationLanguage: '沟通语言',
    WaitingDays: '等候天数'
  }
};

//分页列表页面
export const MedicalCenterList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        {/* <ShowButton {...props} /><br /> */}
        <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
      </div>
    );
  }

  const ContactsInfo = (props) => {
    return (
      <div>
        <TextField {...props} source="Contacts" /><br />
        <TextField {...props} source="ContactsMobiles" /><br />
        <TextField {...props} source="ContactsEmails" />
      </div>
    );
  }

  const NameInfo = (props) => {
    return (
      <div>
        <TextField {...props} source="Name" /><br />
        <TextField {...props} source="NameEN" />
      </div>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />

        <NameInfo label={useTxtData.table.Name} />
        {/* <TextField label={useTxtData.table.Name} source="Name" />
        <TextField label={useTxtData.table.NameEN} source="NameEN" /> */}

        <PreviewImage label={useTxtData.table.Logo} source="Logo" className={classes.imageField} />

        {/* <TextField label={useTxtData.table.CountryID} source="CountryID" /> */}
        <ReferenceField label={useTxtData.table.CountryID} source="CountryID" reference="Admin/CMSCountry" link=''>
          <TextField source="Name" />
        </ReferenceField>

        {/* <TextField label={useTxtData.table.CityID} source="CityID" /> */}
        <ReferenceField label={useTxtData.table.CityID} source="CityID" reference="Admin/CMSCity" link=''>
          <TextField source="Name" />
        </ReferenceField>

        {/* <TextField label={useTxtData.table.TimeZoneID} source="TimeZoneID" /> */}
        <ReferenceField label={useTxtData.table.TimeZoneID} source="TimeZoneID" reference="Admin/CMSTimeZone" link=''>
          <TextField source="Name" />
        </ReferenceField>

        <ContactsInfo label={useTxtData.table.Contacts} />
        {/* <TextField label={useTxtData.table.Contacts} source="Contacts" />
        <TextField label={useTxtData.table.ContactsMobiles} source="ContactsMobiles" />
        <TextField label={useTxtData.table.ContactsEmails} source="ContactsEmails" /> */}

        {/* <TextField label={useTxtData.table.Address} source="Address" /> */}
        {/* <TextField label={useTxtData.table.Introduce} source="Introduce" />
        <TextField label={useTxtData.table.IntroduceEN} source="IntroduceEN" />
        <TextField label={useTxtData.table.Notes} source="Notes" /> */}
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const MedicalCenterShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.Name} source="Name" />
        <TextField label={useTxtData.table.NameEN} source="NameEN" />
        <TextField label={useTxtData.table.Logo} source="Logo" />
        <TextField label={useTxtData.table.CountryID} source="CountryID" />
        <TextField label={useTxtData.table.CityID} source="CityID" />
        <TextField label={useTxtData.table.TimeZoneID} source="TimeZoneID" />
        <TextField label={useTxtData.table.Contacts} source="Contacts" />
        <TextField label={useTxtData.table.ContactsMobiles} source="ContactsMobiles" />
        <TextField label={useTxtData.table.ContactsEmails} source="ContactsEmails" />
        <TextField label={useTxtData.table.Address} source="Address" />
        <TextField label={useTxtData.table.Introduce} source="Introduce" />
        <TextField label={useTxtData.table.IntroduceEN} source="IntroduceEN" />
        <TextField label={useTxtData.table.Notes} source="Notes" />
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const MedicalCenterEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const MedicalCenterCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单

const UseFormComponent = ({ children }) => {
  //两个ReferenceInput联动的问题，默认次级选中的值不会被清空
  //https://github.com/marmelab/react-admin/issues/2431 官方的问题
  //https://github.com/marmelab/react-admin/issues/3676#issuecomment-595240330 解决问题的方法
  const form = useForm();
  if (typeof children === 'function')
    return children && children(form);
};

const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <TabbedForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
      <FormTab label="基础数据">
        <Box display="flex" alignItems="center" style={{}}>
          <Box m={1}>
            <TextInput label={useTxtData.table.Name} source="Name" validate={[required()]} style={{ minWidth: 300 }} />
          </Box>
          <Box m={1}>
            <TextInput label={useTxtData.table.NameEN} source="NameEN" validate={[required()]} style={{ minWidth: 300 }} />
          </Box>
        </Box>

        <Box display="flex" alignItems="center" style={{ width: '50%' }}>
          <Box flex={1} m={1}>
            <UpLoadFile label={useTxtData.table.Logo} source="Logo" validate={[required()]} />
          </Box>
        </Box>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <UseFormComponent>
                {
                  (form) => (
                    <Box display="flex" alignItems="center" style={{}}>
                      <Box m={1}>
                        <ReferenceInput label={useTxtData.table.CountryID} source="CountryID" reference="Admin/CMSCountry" validate={required()}
                          onChange={() => {
                            form.change('CityID', null);
                          }}
                        >
                          <SelectInput optionText="Name" />
                        </ReferenceInput>
                      </Box>
                      <Box m={1}>
                        <ReferenceInput label={useTxtData.table.CityID} source="CityID" reference="Admin/CMSCity" validate={required()}
                          filter={{ "FilterCountryID": true, "CountryID": formData.CountryID }}
                        >
                          <SelectInput optionText="Name" />
                        </ReferenceInput>
                      </Box>
                      <Box m={4} >
                        <ReferenceInput label={useTxtData.table.TimeZoneID} source="TimeZoneID" reference="Admin/CMSTimeZone" validate={required()}>
                          <SelectInput optionText="Name" />
                        </ReferenceInput>
                      </Box>
                    </Box>
                  )
                }
              </UseFormComponent>)
          }}
        </FormDataConsumer>

        <Box display="flex" alignItems="center" style={{}}>
          <Box m={1}>
            <TextInput label={useTxtData.table.Contacts} source="Contacts" validate={[required()]} style={{ minWidth: 200 }} />
          </Box>
          <Box m={1}>
            <TextInput label={useTxtData.table.ContactsMobiles} source="ContactsMobiles" validate={[]} style={{ minWidth: 400 }} />
          </Box>
          <Box m={1}>
            <TextInput label={useTxtData.table.ContactsEmails} source="ContactsEmails" validate={[required()]} style={{ minWidth: 400 }} />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" style={{ width: '50%' }}>
          <Box flex={1} m={1}>
            {/* <TextInput label={useTxtData.table.Address} source="Address" validate={[]} style={{ minWidth: 800 }} /> */}
            <UpLoadFile label={useTxtData.table.Address} source="Address" validate={[]} />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" style={{ width: '50%' }}>
          <Box flex={1} m={1}>
            <NumberInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[required()]} defaultValue={0} />
          </Box>
        </Box>
      </FormTab>
      <FormTab label="介绍">
        <Box display="flex" alignItems="center" style={{}}>
          <Box m={1}>
            <SelectByEnum label={useTxtData.table.HospitalCategory} source="HospitalCategory" EnumName="HospitalCategory" allowEmpty validate={[required()]} />
          </Box>
          <Box m={1}>
            <TextInput label={useTxtData.table.CommunicationLanguage} source="CommunicationLanguage" validate={[]} style={{ minWidth: 400 }} />
          </Box>
        </Box>
        <TextInput label={useTxtData.table.MedicalScope} source="MedicalScope" validate={[]} style={{ width: '100%' }} />

        <CustomRichTextInput label={useTxtData.table.Introduce} source="Introduce" validate={[]} toolbar={[['bold', 'italic', 'underline', 'link', 'image']]} />
        <CustomRichTextInput label={useTxtData.table.IntroduceEN} source="IntroduceEN" validate={[]} toolbar={[['bold', 'italic', 'underline', 'link', 'image']]} />
        <CustomRichTextInput label={useTxtData.table.Notes} source="Notes" validate={[]} toolbar={[['bold', 'italic', 'underline', 'link', 'image']]} />
      </FormTab>
    </TabbedForm>
  );
}