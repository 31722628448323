import * as React from 'react';
import { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import SendIcon from '@material-ui/icons/Send';
import PauseIcon from '@material-ui/icons/Pause';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField as MTextField, Button as MButton } from '@material-ui/core';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, useDataProvider, useRefresh, useNotify } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { UpLoadFile } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
import { apiUrl } from '../global';
import { httpClient } from '../dataProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import qs from 'qs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

//import ResetOrderNum from './_NotificationMessage_ResetOrderNum';

{/* 
  import { _NotificationMessageList, _NotificationMessageCreate, _NotificationMessageEdit, _NotificationMessageShow } from './components/_NotificationMessage';

  <Resource name="CMS_NotificationMessage" list={_NotificationMessageList} create={_NotificationMessageCreate} edit={_NotificationMessageEdit} show={_NotificationMessageShow} />
  <Resource name="CMS_NotificationMessage" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: '_NotificationMessage',
  },
  table: {
    id: 'id',
    Number: '编号',
    Open: '执行/暂停',
    TemplateName: '模板名称',
    CreateTime: '创建时间',
  }
};

//分页列表页面
export const _NotificationMessageList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        {/* <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    //console.log('props-RowAction', props);
    let { Open } = props.record;
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton {...props} /><br /> */}
        {/* <ShowButton {...props} /><br /> */}
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}

        <ChangeOpenStatus {...props} />
      </div>
    );
  }

  const ChangeOpenStatus = (props) => {
    let { id, Open } = props.record;
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const _changeOpenStatus = () => {
      if (window.confirm('确定要执行该操作吗？')) {
        setLoading(true);
        httpClient(`${apiUrl}/Admin/CMS_NotificationMessage/ChangeOpenStatus`, {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: qs.stringify({ id, Open: !Open })
        }).then(({ json }) => {
          setLoading(false);
          if (json.Code === 1) {
            notify('Success');
            refresh();
          }
          else
            notify(json.Message);
        })
      }
    }

    if (loading)
      return (
        <CircularProgress size={15} />
      );

    return (
      <Button color={Open ? 'secondary' : 'primary'} label={Open ? '暂停' : '发送'} onClick={() => { _changeOpenStatus(); }} />
    );
  }

  const ManDialog = (props) => {
    let { id, type, edit, name, emailOrMobile, open, handleClose } = props;

    const [newName, setNewName] = useState(name);
    const [newEmailOrMobile, setEmailOrMobile] = useState(emailOrMobile);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const setNameVal = (event) => {
      setNewName(event.target.value);
    }
    const setEmailOrMobileVal = (event) => {
      setEmailOrMobile(event.target.value);
    }
    const _handleClose = () => {
      if (!loading) {
        setNewName(name);
        setEmailOrMobile(emailOrMobile);
        handleClose && handleClose();
      }
    }
    const handleSubmit = () => {
      if (loading) return;
      if (window.confirm('确定要执行该操作吗？')) {
        setLoading(true);
        httpClient(`${apiUrl}/Admin/CMS_NotificationMessage/AddOrEditRecipient`, {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: qs.stringify({ id, type, edit, name, emailOrMobile, newName, newEmailOrMobile })
        }).then(({ json }) => {
          if (json.Code === 1) {
            notify('Success', 'success');
            refresh();
          }
          else
            notify(json.Message, 'info');
        }).catch((err) => {
          notify(`异常: ${err}`, 'error');
        }).finally(() => {
          setLoading(false);
        });
      }
    }

    return (
      <>
        <Dialog open={open} onClose={_handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{edit ? '编辑/删除' : '增加'}人员</DialogTitle>
          <DialogContent style={{ minWidth: 400 }}>
            <MTextField
              autoFocus
              margin="dense"
              label={"名称"}
              type="text"
              fullWidth
              value={newName}
              onChange={setNameVal}
            />
            <MTextField
              margin="dense"
              label={(type === 'mobile' ? '手机号' : '邮箱') + ' (必填)'}
              type="text"
              fullWidth
              value={newEmailOrMobile}
              onChange={setEmailOrMobileVal}
            />
          </DialogContent>
          <DialogActions>
            {edit && <DeleteMan {...props} />}
            <Box style={{ flex: 1 }}></Box>
            {
              !loading && <MButton disabled={loading} onClick={_handleClose} color="primary">
                取消
              </MButton>
            }
            <MButton disabled={loading} onClick={handleSubmit} color="primary">
              {loading ? <CircularProgress size={15} /> : null} <span>保存</span>
            </MButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  const DeleteMan = (props) => {
    let { id, type, name, emailOrMobile } = props;
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const deleteData = () => {
      if (loading) return;
      if (window.confirm('确定要执行删除操作吗？')) {
        setLoading(true);
        httpClient(`${apiUrl}/Admin/CMS_NotificationMessage/DeleteRecipient`, {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: qs.stringify({ id, type, name, emailOrMobile })
        }).then(({ json }) => {
          setLoading(false);
          if (json.Code === 1) {
            notify('Success')
            refresh();
          }
          else
            notify(json.Message);
        })
      }
    };

    return (
      <>
        <MButton disabled={loading} onClick={loading ? null : () => deleteData()} color="primary" style={{ color: 'red' }}>
          {loading ? <CircularProgress size={15} /> : null} <span>删除</span>
        </MButton>
      </>
    );
  }

  const ManContrl = (props) => {
    let { disabled, id, type, name, emailOrMobile } = props;

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    }

    let data = { id, type, edit: true, name, emailOrMobile, open, handleClose };
    return (
      <Box mr={1} mb={1}><Chip onClick={disabled ? () => handleClickOpen() : null} label={`${emailOrMobile}` + (name.length > 0 ? `(${name})` : '')} color="primary" variant="outlined" size="small" />
        {disabled &&
          <ManDialog {...data} />
        }
      </Box>
    );
  }

  const AddMan = (props) => {
    let { id, type } = props;

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    }

    let data = { id, type, edit: false, name: '', emailOrMobile: '', open, handleClose };
    return (
      <>
        <AddCircleOutlineIcon style={{ cursor: 'pointer' }} onClick={() => { handleClickOpen() }} color='primary' />
        <ManDialog {...data} />
      </>
    );
  }

  const PostPanel = ({ id, record, resource }) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const notify = useNotify();

    useEffect(() => {
      httpClient(`${apiUrl}/Admin/CMS_NotificationMessage/GetDetail`, {
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        body: qs.stringify({ Number: record.Number })
      }).then(({ json }) => {
        setLoading(false);
        if (json.Code === 1)
          setData(json.Result);
        else
          notify(json.Message);
      })
    }, []);

    if (loading) return (
      <Box display="flex" alignItems="center"><CircularProgress size={15} /><Box m={1}>数据加载中...</Box></Box>
    );

    if (!data) return <Box>数据为空</Box>;

    let { Open } = record;
    return (
      <Box display='flex'>
        <Box flex={1} m={1}>
          <Typography variant="h6" gutterBottom component="div">
            短信
          </Typography>
          {data.mobile.data.length > 0 ? <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>接收对象</TableCell>
                  <TableCell>内容</TableCell>
                  <TableCell>状态</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data.mobile.data.map((item, index) => {
                    let disabled = !item.Success && !Open;
                    let Addressee = JSON.parse(item.Addressee);
                    return (
                      <TableRow key={index}>
                        <TableCell style={{ verticalAlign: 'top' }}>
                          <Box display='flex' flexWrap="wrap">
                            {
                              Addressee.map((address) => {
                                return (
                                  <ManContrl disabled={disabled} id={item.id} type={'mobile'} name={address.Name} emailOrMobile={address.Mobile} />
                                )
                              })
                            }
                            {disabled && <Box><AddMan id={item.id} type={'mobile'} /></Box>}
                          </Box>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>{item.Text}</TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                          {
                            <Box display='flex' alignItems='center'>
                              <Box>
                                {item.Success ?
                                  <DoneIcon style={{ color: 'green' }} />
                                  : Open ? <SendIcon style={{ color: 'green' }} /> : <PauseIcon />
                                }
                              </Box>
                              <Box ml={1}>{item.SendCount}</Box>
                            </Box>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer> : <span>没有内容</span>
          }
        </Box>
        <Box flex={1} m={1}>
          <Typography variant="h6" gutterBottom component="div">
            邮件
          </Typography>
          {data.email.data.length > 0 ? <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>接收对象</TableCell>
                  <TableCell>内容</TableCell>
                  <TableCell>状态</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data.email.data.map((item, index) => {
                    let disabled = !item.Success && !Open;
                    let Addressee = JSON.parse(item.Addressee);
                    return (
                      <TableRow key={index}>
                        <TableCell style={{ verticalAlign: 'top' }}>
                          <Box display='flex' flexWrap="wrap">
                            {
                              Addressee.map((address) => {
                                return (
                                  <ManContrl disabled={disabled} id={item.id} type={'email'} name={address.Name} emailOrMobile={address.Email} />
                                )
                              })
                            }
                            {disabled && <Box><AddMan id={item.id} type={'mobile'} /></Box>}
                          </Box>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                          <Box m={1}>
                            <b>{item.Title}</b>
                          </Box>
                          <Box m={1}>
                            <div dangerouslySetInnerHTML={{ __html: item.HTML }} />
                          </Box>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                          {
                            <Box display='flex' alignItems='center'>
                              <Box>
                                {item.Success ?
                                  <DoneIcon style={{ color: 'green' }} />
                                  : Open ? <SendIcon style={{ color: 'green' }} /> : <PauseIcon />
                                }
                              </Box>
                              <Box ml={1}>{item.SendCount}</Box>
                            </Box>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer> : <span>没有内容</span>
          }
        </Box>
      </Box >
    )
  };

  const Status = (props) => {
    let { Open, Status, Count } = props.record;

    if (Open)
      return (
        <Box display='flex' alignItems='center'>
          <Box>{Status === "Done" ? <DoneIcon style={{ color: 'green' }} /> : <SendIcon style={{ color: 'green' }} />}</Box>
          <Box ml={1}>{Count}</Box>
        </Box>
      );
    else
      return (
        <Box display='flex' alignItems='center'>
          <Box><PauseIcon /></Box>
          <Box ml={1}>{Count}</Box>
        </Box>
      );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid expand={<PostPanel />}>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.Number} source="Number" />
        <Status label={useTxtData.table.Open} />
        <TextField label={useTxtData.table.TemplateName} source="TemplateName" />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
// export const _NotificationMessageShow = (props) => {
//   const ShowActions = (props) => {
//     const { basePath, data } = props; const redirect = useRedirect();
//     return (
//       <TopToolbar>
//         <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
//           <ArrowBack />
//         </Button>
//         <EditButton basePath={basePath} record={data} />
//       </TopToolbar>
//     );
//   };

//   const classes = useStyles();
//   return (
//     <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
//       <SimpleShowLayout>
//         {/* <TextField source="id" /> */}
//         {/* <TextField label="名称" source="Name" /> */}
//         {/* <TextField label="排序" source="OrderNum" />  */}
//         <TextField label={useTxtData.table.id} source="id" />
//         <TextField label={useTxtData.table.Number} source="Number" />
//         <TextField label={useTxtData.table.Open} source="Open" />
//         <TextField label={useTxtData.table.TemplateName} source="TemplateName" />
//         <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
//       </SimpleShowLayout>
//     </Show>
//   );
// }

// ////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////

// //编辑页面
// export const _NotificationMessageEdit = (props) => {
//   const PostEditActions = (props) => {
//     const { basePath } = props; const redirect = useRedirect();
//     return (
//       <TopToolbar {...props}>
//         <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
//           <ArrowBack />
//         </Button>
//       </TopToolbar>
//     );
//   };
//   const PostEditToolbar = (props) => {
//     return (
//       <Toolbar {...props}>
//         <SaveButton />
//       </Toolbar>
//     );
//   };
//   return (
//     <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
//       <MyForm Edit={true} toolbar={<PostEditToolbar />} />
//     </Edit>
//   );
// };

// ////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////

// //创建页面
// export const _NotificationMessageCreate = (props) => {
//   const PostCreateActions = (props) => {
//     const { basePath } = props; const redirect = useRedirect();
//     return (
//       <TopToolbar {...props}>
//         <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
//           <ArrowBack />
//         </Button>
//       </TopToolbar>
//     );
//   };
//   const PostCreateToolbar = (props) => {
//     const { basePath } = props;
//     return (
//       <Toolbar {...props}>
//         <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
//         <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
//       </Toolbar>
//     );
//   };

//   return (
//     <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
//       <MyForm Create={true} toolbar={<PostCreateToolbar />} />
//     </Create>
//   );
// };

// ////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////
// //Create和Edit公用表单
// const MyForm = (props) => {
//   let { Edit, Create } = props;
//   return (
//     <SimpleForm {...props} >
//       {/* <TextInput source="Name" /> */}
//       {/* <NumberInput source="OrderNum" /> */}
//       <TextInput label={useTxtData.table.id} source="id" validate={[required()]} />
//       <TextInput label={useTxtData.table.Number} source="Number" validate={[required()]} />
//       <TextInput label={useTxtData.table.Open} source="Open" validate={[required()]} />
//       <TextInput label={useTxtData.table.TemplateName} source="TemplateName" validate={[required()]} />
//       <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} />
//     </SimpleForm>
//   );
// }