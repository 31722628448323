import * as React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import { Route } from 'react-router-dom';
import jsonServerProvider from 'ra-data-json-server';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chinese from 'ra-language-chinese';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import MyLayout from './customLayout/layout';
import LoginPage from './loginPage';
import Dashboard from './dashboardPage';

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CategoryIcon from '@material-ui/icons/Category';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SortIcon from '@material-ui/icons/Sort';
import MessageIcon from '@material-ui/icons/Message';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import ChangePwd from './customLayout/ChangePwd';

import { AdminList, AdminCreate, AdminEdit } from './components/Admin';
import { HelpSortList, HelpSortCreate, HelpSortEdit } from './components/HelpSort';
import { HelpList, HelpCreate, HelpEdit, HelpShow } from './components/Help';
import { DoctorSortList, DoctorSortCreate, DoctorSortEdit } from './components/DoctorSort';
import { CountryList, CountryCreate, CountryEdit } from './components/Country';
import { CityList, CityCreate, CityEdit } from './components/City';
import { TimeZoneList, TimeZoneCreate, TimeZoneEdit } from './components/TimeZone';
import { MedicalCenterList, MedicalCenterCreate, MedicalCenterEdit, MedicalCenterShow } from './components/MedicalCenter';
import { ReferralCenterList, ReferralCenterCreate, ReferralCenterEdit, ReferralCenterShow } from './components/ReferralCenter';
import { DoctorList, DoctorCreate, DoctorEdit, DoctorShow } from './components/Doctor';
import { _NotificationMessageList } from './components/_NotificationMessage';
import { MemberList, MemberEdit, MemberShow } from './components/Member';
import { Member2List, Member2Edit, Member2Show } from './components/Member2';
import { PlatforMemberSortList, PlatforMemberSortCreate, PlatforMemberSortEdit } from './components/PlatforMemberSort';
import { PlatforMemberList, PlatforMemberCreate, PlatforMemberEdit } from './components/PlatforMember';


//补充缺失的翻译内容 一下是增加一个confirm的中文翻译
chinese.ra.action = { ...chinese.ra.action, confirm: '确认' };
//设置语言为 中文
const i18nProvider = polyglotI18nProvider(() => chinese, 'zh');

const customRoutes = [
  <Route exact path="/ChangePwd" component={ChangePwd} />,
];

console.log('NODE_ENV', process.env.NODE_ENV)

const App = () => (
  <Admin
    title="细柔医疗后台管理"
    layout={MyLayout}
    customRoutes={customRoutes}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
    dashboard={Dashboard}
  >
    <Resource options={{ group: '设置', label: '管理员' }} name="Admin/CMSAdmin" list={AdminList} icon={<VerifiedUserIcon />} />
    <Resource options={{ group: '设置', label: '国家' }} name="Admin/CMSCountry" list={CountryList} icon={<LanguageIcon />} />
    <Resource options={{ group: '设置', label: '城市' }} name="Admin/CMSCity" list={CityList} icon={<LocationCityIcon />} />
    <Resource options={{ group: '设置', label: '时区' }} name="Admin/CMSTimeZone" list={TimeZoneList} icon={<AccessTimeIcon />} />
    <Resource options={{ group: '设置', label: '成员分类' }} name="Admin/CMSPlatforMemberSort" list={PlatforMemberSortList} icon={<SortIcon />} />
    <Resource options={{ group: '设置', label: '成员信息' }} name="Admin/CMSPlatforMember" list={PlatforMemberList} icon={<AccountBoxIcon />} />

    <Resource options={{ group: '帮助', label: '分类' }} name="Admin/CMSHelpSort" list={HelpSortList} icon={<HelpIcon />} />
    <Resource options={{ group: '帮助', label: '信息' }} name="Admin/CMSHelp" list={HelpList} create={HelpCreate} edit={HelpEdit} show=
      {HelpShow} icon={<InfoIcon />} />

    <Resource options={{ group: '信息&推送', label: '信息' }} name="Admin/CMS_NotificationMessage" list={_NotificationMessageList} icon={<MessageIcon />} />

    <Resource options={{ group: '会员&分销', label: '会员' }} name="Admin/CMSMember" list={MemberList} /* create={MemberCreate}*/ edit={MemberEdit} show={MemberShow} icon={<PeopleIcon />} />
    <Resource options={{ group: '会员&分销', label: '服务商' }} name="Admin/CMSMember2" list={Member2List} /* create={MemberCreate}*/ edit={Member2Edit} show={Member2Show} icon={<PeopleIcon />} />

    <Resource options={{ group: '转诊&供应', label: '转诊中心' }} name="Admin/CMSReferralCenter" list={ReferralCenterList} create={ReferralCenterCreate} edit={ReferralCenterEdit} show={ReferralCenterShow} icon={<AccessibilityNewIcon />} />
    <Resource options={{ group: '转诊&供应', label: '医疗机构' }} name="Admin/CMSMedicalCenter" list={MedicalCenterList} create={MedicalCenterCreate} edit={MedicalCenterEdit} show={MedicalCenterShow} icon={<LocalHospitalIcon />} />

    <Resource options={{ group: '医疗&服务', label: '分类' }} name="Admin/CMSDoctorSort" list={DoctorSortList} create={DoctorSortCreate} edit={DoctorSortEdit} icon={<SortIcon />} />
    <Resource options={{ group: '医疗&服务', label: '医生' }} name="Admin/CMSDoctor" list={DoctorList} create={DoctorCreate} edit={DoctorEdit} show={DoctorShow} icon={<AssignmentIndIcon />} />

  </Admin>
);

export default App;