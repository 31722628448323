import { Layout } from 'react-admin';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MyMenu from './menu';
import MyAppBar from './appBar';

const useStyles = makeStyles({
    Layout: {
        '& .MuiPaper-root > div[class^="RaSidebar-fixed-"]': { width: 55 }
    },
});


const MyLayout = (props) => {
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const classes = useStyles();
    return (
        <Layout {...props} menu={MyMenu} appBar={MyAppBar} className={!open ? classes.Layout : []} />
    )
}

export default MyLayout;