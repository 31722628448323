import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { images } from './imageresource';


//生成从minNum到maxNum的随机数
function randomNum(minNum, maxNum) {
    switch (arguments.length) {
        case 1:
            return parseInt(Math.random() * minNum + 1, 10);
            break;
        case 2:
            return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
            break;
        default:
            return 0;
            break;
    }
}
var i = randomNum(0, images.length - 1);
let backgroundImage = (process.env.NODE_ENV === 'development' ? "http://" : "https://") + "linju1020.gitee.io/randomimage" + '/images/' + images[i];

const styles = {
    login: {
        main: {
            backgroundImage: `url(${backgroundImage})`,
        },
        card: {
            padding: '5px 0 15px 0',
        },
    },
    form: {
        button: {
            height: '3em',
        },
    },
};

const MyLoginForm = withStyles(styles.form)(LoginForm);

const MyLogin = (props) => (
    <Login loginForm={<MyLoginForm />} {...props} />
);

export default withStyles(styles.login)(MyLogin);