import { fetchUtils, HttpError } from 'react-admin';
import { stringify } from 'query-string';
import { apiUrl } from './global';

//const httpClient = fetchUtils.fetchJson;
export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    //options.headers = new Headers({ Accept: 'application/json' });
    options.headers = new Headers();
  }
  // add your own headers here
  const token = localStorage.getItem('token');
  options.headers.set('authorization', token);
  if (options.contentType)
    options.headers.set('Content-Type', options.contentType);
  return fetchUtils.fetchJson(url, options);
};

const uploadFile = file =>
  new Promise(async (resolve, reject) => {
    //console.log('form value', file);
    if (file == null) {
      resolve(file);
    }
    else if (file.rawFile instanceof File) {
      //upload file
      var formData = new FormData();
      formData.append("file", file.rawFile);
      httpClient(`${apiUrl}/Admin/File/UploadFile`, {
        method: 'POST',
        body: formData
      }).then(({ json }) => {
        if (json.Code == 1)
          resolve(json.Result.filePath);
        reject(json.Message);
      })
    }
    else if (Object.prototype.toString.call(file) === '[object Object]') {
      // Nested object
      file = await getUrlFromFiles(file)
        .then(res => res)
        .catch(e => { reject(e) });
      resolve(file);
    } else {
      //already url
      resolve(file);
    }
  })

const getUrlFromFiles = data => {
  //console.log('data',data);return;
  return new Promise(async (resolve, reject) => {
    for (let key of Object.keys(data)) {
      let isArray = data[key] instanceof Array;
      let dataArr = isArray ? data[key] : [data[key]];
      if (dataArr.length > 0) {
        let results = await Promise.all(dataArr.map(uploadFile))
          .then(res => res).catch(e => { reject(e); })
        data[key] = isArray ? results : results[0];
      }
    };
    resolve(data);
  })
}

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...{ _page: page - 1, _pagesize: perPage }, //JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      ...{ _sort: field, _order: order }, //JSON.stringify([field, order]),
      ...params.filter,
    };

    //console.log('process.env.NODE_ENV:'+process.env.NODE_ENV);

    const url = `${apiUrl}/${resource}/GetList?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ''));
      return { data: json.Result.data, total: json.Result.total };
    });
  },

  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/GetOne/${params.id}`).then(({ json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ''));
      return { data: json.Result };
    });
  },

  getMany: (resource, params) => {
    console.log('params',params)
    const query = {
      ids: params.ids,
    };
    const url = `${apiUrl}/${resource}/GetMany?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ''));
      return { data: json.Result };
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    /* const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }; */
    const query = {
      ...{ _page: page - 1, _pagesize: perPage }, //JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      ...{ _sort: field, _order: order }, //JSON.stringify([field, order]),
      ...{ [params.target]: params.id },
      ...params.filter,
    };

    const url = `${apiUrl}/${resource}/GetList?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ''));
      return { data: json.Result.data, total: json.Result.total };
    });
  },

  create: (resource, params) => {
    return new Promise(async (resolve, reject) => {
      //If has Files, get file url from upload file first
      params.data = await getUrlFromFiles(params.data)
        .then(res => res)
        .catch(e => { reject(new HttpError(e, 406, '')) });

      httpClient(`${apiUrl}/${resource}/Create`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        if (json.Code == 0)
          reject(new HttpError(json.Message, 406, ''));
        resolve({ data: json.Result });
      })
    });
  },

  update: (resource, params) => {
    return new Promise(async (resolve, reject) => {
      //If has Files, get file url from upload file first
      params.data = await getUrlFromFiles(params.data)
        .then(res => res)
        .catch(e => { reject(new HttpError(e, 406, '')) });

      httpClient(`${apiUrl}/${resource}/Update/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        if (json.Code == 0)
          reject(new HttpError(json.Message, 406, ''));
        resolve({ data: json.Result });
      })
    });
  },

  updateMany: (resource, params) => {
    const query = {
      ids: params.ids,
    };
    return httpClient(
      `${apiUrl}/${resource}/UpdateMany/${params.data.payload}?${stringify(
        query
      )}`,
      {
        method: 'PUT',
        body: JSON.stringify(params.data.data),
      }
    ).then(({ json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ''));
      return { data: json.Result };
    });
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/Delete/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ''));
      return { data: json.Result };
    }),

  deleteMany: (resource, params) => {
    // const query = {
    //   filter: JSON.stringify({id: params.ids}),
    // };
    //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
    return httpClient(`${apiUrl}/${resource}/DeleteMany`, {
      method: 'DELETE',
      body: JSON.stringify({ ids: params.ids }),
    }).then(({ json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ''));
      return { data: json.Result };
    });
  },
};