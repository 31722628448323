import * as React from "react";
import { Card, CardContent, CardHeader, Box } from '@material-ui/core';
import { webUrl } from './global.js'

const dashboard = () => (
    <Card>
        <CardHeader title="控制台" />
        <CardContent>
            <Box>
                <a href={webUrl} target='_blank'>网站前端</a>
            </Box>
        </CardContent>
    </Card>
);


export default dashboard;