import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
import { apiUrl } from '../global';
//import ResetOrderNum from './DoctorSort_ResetOrderNum';

{/* 
  import { DoctorSortList, DoctorSortCreate, DoctorSortEdit, DoctorSortShow } from './components/DoctorSort';

  <Resource name="CMSDoctorSort" list={DoctorSortList} create={DoctorSortCreate} edit={DoctorSortEdit} show={DoctorSortShow} />
  <Resource name="CMSDoctorSort" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
  imageInput: {
    '& img': { width: 120 }
  },
});

const useTxtData = {
  page: {
    tableName: 'DoctorSort',
  },
  table: {
    id: 'id',
    SortName: '分类名称',
    ParentProductSortID: '父级分类',
    IconWhite: '白色图标',
    IconColor: '彩色图标',
    AdPic: '广告图',
    OrderNum: '排序',
    Lock: '锁定',
  }
};

//分页列表页面
export const DoctorSortList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        {/* <TextInput label="ID" source="id" alwaysOn resettable /> */}
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
      </div>
    );
  }

  const PCName = (props) => {
    var ji = props.record['Ji'];
    return (
      <div>
        {ji === 1 && <span>----</span>}
        {ji === 2 && <span>--------</span>}
        <TextField source="SortName" {...props} />
      </div>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'ASC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        {/* <TextField label={useTxtData.table.SortName} source="SortName" />*/}
        <PCName label={useTxtData.table.SortName} />
        {/* <TextField label={useTxtData.table.ParentProductSortID} source="ParentProductSortID" /> */}
        
        <PreviewImage className={classes.imageField} label={useTxtData.table.IconWhite} source="IconWhite" />
        <PreviewImage className={classes.imageField} label={useTxtData.table.IconColor} source="IconColor" />

        <PreviewImage className={classes.imageField} label={useTxtData.table.AdPic} source="AdPic" />
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
        <BooleanField label={useTxtData.table.Lock} source="Lock" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const DoctorSortEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const DoctorSortCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  const classes = useStyles();
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
      <TextInput label={useTxtData.table.SortName} source="SortName" validate={[required()]} />
      <MyReferenceInput />
      <UpLoadFile className={classes.imageInput} label={useTxtData.table.IconWhite} source="IconWhite" validate={[required()]} />
      <UpLoadFile className={classes.imageInput} label={useTxtData.table.IconColor} source="IconColor" validate={[required()]} />

      <UpLoadFile className={classes.imageInput} label={useTxtData.table.AdPic} source="AdPic" validate={[]} />
      <NumberInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[required()]} defaultValue={0} />
      <BooleanInput label={useTxtData.table.Lock} source="Lock" validate={[required()]} defaultValue={false} />
    </SimpleForm>
  );
}

const MyReferenceInput = (props) => {
  let isTip = props.record.Ji === 0;
  return (
    <ReferenceInput disabled={isTip} label={useTxtData.table.ParentProductSortID} source="ParentProductSortID" allowEmpty reference="Admin/CMSDoctorSort" sort={{ field: 'OrderNum', order: 'DESC' }} validate={[required()]} {...props}>
      <MySelectInput optionText="Name" />
    </ReferenceInput>
  )
}

const MySelectInput = (props) => {
  let { choices } = props;
  let mychoices = choices.map(item => {
    console.log('item', item);
    let qz = '';
    if (item['Ji'] == 1) qz = '----'
    else if (item['Ji'] == 2) qz = '--------'
    return { id: item.id, Name: (qz + item.SortName), disabled: item['Ji'] !== 0 };
  });
  mychoices = [{ id: 0, Name: '顶级', disabled: false }, ...mychoices];

  return <SelectInput {...props} choices={mychoices} />
}